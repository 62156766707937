import React from "react";
import "./ArtisanMainSide.css";
import { BsSearch } from "react-icons/bs";
import { VscSettings } from "react-icons/vsc";
import ProjetCard from "../projetCard/ProjetCard";

const ArtisanMainSide = ({ authUser }) => {
  return (
    <div className="M-artisan-wrapper mx-auto">
      <div className="artisan-home paddings innerWidth mx-auto">
        <div className="flex  items-center justify-center mx-auto gap-3 md:gap-8">
          <div className="flex gap-4 bg-white w-full p-2 border-1 border-gray-200">
            <BsSearch color="var(--blue)" size={25} />
            <input
              type="text"
              className="outline-none w-full"
              placeholder="Rechercher par mot-clé, ville, catégories"
            />
            {/* <button className="button">Rercherche</button> */}
          </div>
          <button className="button2 items center">
            <VscSettings /> Filtrer
          </button>
        </div>

        <ProjetCard authUser={authUser} />
      </div>
    </div>
  );
};

export default ArtisanMainSide;
