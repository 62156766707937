import React from 'react'
import OfferCard from '../../components/OfferCard/OfferCard'

const OfferPage = () => {
  return (
    <div className='innerWidth paddings'>
      <OfferCard/>
    </div>
  )
}

export default OfferPage
