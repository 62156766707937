export const sliderSettings = {
    slidesPerView : 1,
    spaceBetween : 10, 
    breakpoints :{
        480 : {
            slidesPerView: 1
        }, 
        600 :{
            slidesPerView: 2
        },
        750 : {
            slidesPerView: 4
        },
        1100: {
            slidesPerView: 5
        }
    }
}