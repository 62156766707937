import React from "react";
import "./Blog.css"
import { posts } from "./dataBlog";
import Blogcard from "./Blogcard";

const Blog = () => {
  return (
    <section className="b-wrapper d-flex">
      <div className="innerWidth paddings b-container">
        <div className="t-head flexColStart">
          <span className="primaryText">Bon à savoir</span>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-3 md:gap-8">
            {posts.map(post => (
                <Blogcard key={post.id} post ={post}/>
            ))}
        </div>

      </div>
    </section>
  );
};

export default Blog;
