import React from "react";
import "./AdminHome.css";
import AdminSidebar from "../../../components/AdminDashboard/AdminSidebar/AdminSidebar";
import AdminNavbar from "../../../components/AdminDashboard/AdminNavbar/AdminNavbar";
import Widget from "../../../components/AdminDashboard/Widget/Widget";
import Featured from "../../../components/AdminDashboard/Featured/Featured";
import Chart from "../../../components/AdminDashboard/Charts/Charts";
import Table from "../../../components/AdminDashboard/table/Table"

const AdminHome = () => {
  return (
    <div className="admin-home">
      <AdminSidebar />
      <div className="homeContainer">
        <AdminNavbar />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="artisans" />
          <Widget type="projets" />
          <Widget type="paiements" />
        </div>
        <div className="charts">
          <Featured />
          <Chart aspect={2/1} title="chiffre d'affaires des 6 derniers mois"/>
        </div>
        <div className="listContainer">
          <div className="listTitle">Dernières transactions</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
