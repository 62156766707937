import React from "react";

const StepperControl = ({ handleClick, currentStep, steps, submitData }) => {
  return (
    <div className="container flex justify-around mt-4 mb-8">
      {/* Back button  */}
      <button
        onClick={() => handleClick()}
        className={`text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer hover:bg-black border-2  hover:text-white transition  duration-200 ease-in-out ${
          currentStep === 1 ? "opacity-20 cursor-not-allowed" : ""
        }`}
      >
        Retour
      </button>
      {/* Back button  */}
      <button
        onClick={
          currentStep === steps.length
            ?() => submitData()
            :() => handleClick("next")
        }
        className="bg-red-600 text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer hover:bg-red-900 hover:text-white transition  duration-200 ease-in-out"
      >
        {currentStep === steps.length ? "Confirmer" : "Suivant"}
      </button>
    </div>
  );
};

export default StepperControl;
