import React from 'react'
import './ChatPage.css'
import ChatsList from '../../components/ChatComponents/ChatsList'

const ChatPage = () => {
  return (
    <div className='flexCenter innerWidth pt-14 lg:pt-4'>
      <div className="chat-container">
        <ChatsList/>
      </div>
    </div>
  )
}
 
export default ChatPage
