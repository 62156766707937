import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import { FaSort } from "react-icons/fa";
import ChatCard from "./ChatCard";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import moment from "moment";

const ChatsList = () => {
  const [conversations, setConversations] = useState([]);
  const [color, setColor] = useState("");

  const goToChats = (conversationId) => {
    window.location.href = `conversation/${conversationId}`;
  };
console.log(conversations)
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== undefined) {
      axios
        .get(`${baseUrl}/conversation`, {
          headers: {
            "x-access-token": `${token}`,
          },
        })
        .then((res) => {
          setConversations(res.data.conversations);
        });
    }
  }, []);

  useEffect(() => {
    const val =
      "#" + Math.floor(Math.random() * (256 * 256 * 256)).toString(16);
    setColor(val);
  }, []);

  return (
    <div>
      <div className="flex justify-between mx-1">
        <div className="flex justify-start items-center w-full bg-white rounded-full border-1 border-red-100 mt-4 w-1/3 p-2">
          <BsSearch color="var(--red)" size={25} className="" />
          <input
            type="text"
            className="w-full mx-2 border-none outline-none"
            placeholder="Recherche"
          />
        </div>
        <div className="mt-4 flex items-center">
          {/* <p>1-20 sur 320 conversation</p> */}
          <div className="p-2 rounded-full bg-white-700 border-1 ml-4 cursor-pointer">
            <FaRegEnvelopeOpen size={22} color="var(--red)" />
          </div>
          <div className="p-2 rounded-full bg-white-700 border-1 mx-2 cursor-pointer">
            <FaSort size={22} color="var(--red)" />
          </div>
        </div>
      </div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24">
        <div className="grid gap-2 row-gap-8 mx-auto sm:row-gap-10 lg:max-w-screen-lg w-full">
          {conversations?.map((conversation, i) => (
            <div
              className="flex bg-cyan-50 rounded cursor-pointer border-1 border-sky-100"
              onClick={() => goToChats(conversation._id)}
              key={i}
            >
                <div
                  className="w-16 h-14 rounded-full flex justify-center items-center m-2"
                  style={{ background: `${color}` }}
                >
                  <span className="flex justify-center items-center font-medium text-2xl text-white">
                    {conversation.project.user.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="flex justify-between w-full cursor-pointer">
                  <div className="flex flex-col justify-center mx-2 lg:mx-4">
                    <p className="text-lg font-medium text-gray-600 px-2">
                      {conversation.project.user.name}|
                      <label className="mx-2 text-sm">
                        {conversation.project.title.substring(0, 51)}{"..."}
                      </label>{" "}
                    </p>
                    <p className="text-sm text-gray-600 px-2 text-to-truncate">
                      {conversation?.messages[conversation.messages.length -1]?.content}...
                    </p>
                  </div>
                  <div className="flex flex-end mx-2 lg:mx-4">
                    <p className="text-sm font Bold px-2 py-2 mt-3">{moment(conversation?.messages[conversation.messages.length -1].date).fromNow()}</p>
                  </div>
                </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatsList;
