import React from 'react'

const Account = ({userData, setUserData}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  }; 


  return (
    <div className="mt-8 grid grid-cols-6 gap-6">
    <div className="col-span-6 sm:col-span-3">
      <label
        htmlFor="prenom"
        className="block text-sm font-medium text-gray-700"
      >
        Email :
      </label>

      <input
        onChange={handleChange}
        value={userData["email"] || ""}
        type="email"
        id="email"
        name="email"
        placeholder="adresse mail"
        className="mt-1 w-full rounded-md border-gray-200 border-1  bg-white text-sm text-gray-700 shadow-sm p-2"
      />
    </div>

    <div className="col-span-6 sm:col-span-3">
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        Mot de passe :
      </label>

      <input
       onChange={handleChange}
       value={userData["pass"] || ""}
        type="password"
        id="pass"
        name="pass"
        placeholder="Mot de passe"
        className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
        rules={[
          { required: true, message: "Entrez votre mot de passes" },
          {
            pattern:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            message: `Minimum de huit caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial`,
          },
        ]}
      />
    </div>

    <div className="col-span-6 sm:col-span-3">
      <label
        htmlFor="adresse"
        className="block text-sm font-medium text-gray-700"
      >
        Confirmez votre mot de passes :
      </label>

      <input
       onChange={handleChange}
       value={userData["confirmPass"] || ""}
        type="password"
        id="confirmPass"
        name="confirmPass"
        placeholder="Confirmez votre mot de passes"
        className="mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2"
      />
    </div> 
  </div>
  )
}

export default Account
