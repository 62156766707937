
import './ArtisanDashboard.css'
import ArtisanMainSide from '../../components/DashboardArtisan/ArtisanMainSide'

const ArtisantDashboard = ({authUser}) => {
  return (
  <div className='Artisan-Dashboard innerWidth'>
    <ArtisanMainSide authUser = {authUser}/>
  </div>
  )
}

export default ArtisantDashboard