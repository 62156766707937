import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";
import { baseUrl } from "../../utils/constant_api";
import axios from 'axios';

const StripeContainer = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    axios.get(`${baseUrl}/payment/config`)
    .then((response) => {
      const publishableKey  =  response.data.pubKey;
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    axios
    .post(`${baseUrl}/payment/create-payment-intent`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'x-access-token': `${token}`
      },
    }).then((response) => {
      setClientSecret(response.data.clientSecret);
    });
  }, []);

  return (
    <div className="">
      {stripePromise && clientSecret &&(
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripeForm />
          </Elements>
        )}
    </div>
  );
};

export default StripeContainer;
