import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
const AdminPrivateRoute = () => {
  let token = localStorage.getItem("token");
  let role = "role";


    if (token !== null && token !== undefined) {
      role = localStorage.getItem("userRole");
    } else {
      window.location.href = "/connexion";
    }


  return <div>{role !== "admin" ? <Navigate to="/login" /> : <Outlet />}</div>;
};

export default AdminPrivateRoute;