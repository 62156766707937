import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { IoMdPricetags } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import Messages from "./Messages";
import Input from "./Input";
import socketio from "socket.io-client";
import { IoMdAttach } from "react-icons/io";
import { LuImagePlus } from "react-icons/lu";
import { baseUrl } from "../../utils/constant_api";

const Conversation = ({ authUser }) => {
  const [chat, setChat] = useState({});
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]); 

  const onMessageChange = (e) => {
    setMessage(e.target.value)
  }


  const handleSubmit = () => {
      const token = localStorage.getItem('token')
      if(token !== null && token !== undefined) {
        axios.post(`${baseUrl}/conversation/${id}/message`, {message}, {
          headers: {
            'x-access-token': `${token}`
          }
        }).then((res) => {
          setMessage('')
          setMessages(messages =>  [...messages, res.data.message]);
          getConversations();
        })
      }
  }

  const { id } = useParams();

  const getConversations = async () => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== undefined) {
      await axios
        .get(`${baseUrl}/conversation/${id}`, {
          headers: {
            "x-access-token": `${token}`,
          },
        })
        .then((res) => {
          setChat(res.data.conversation);
          setMessages(res.data.conversation.messages)
          console.log(chat);
        });
    }
  };

  useEffect(() => {
    getConversations();
  }, []);

  return (
    <div className="paddings innerWidth pt-14 lg:pt-4">
      <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen">
        <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200">
          <div className="flex justify-between bg-gray-900 rounded-lg w-full">
            <div className="projectInfo w-2/5">
              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between w-full gap-1.5 rounded-lg bg-gray-50 p-1 text-gray-900 relative">
                  <img
                    className="object-cover w-12 h-12 mr-4 p-1 rounded-full shadow"
                    src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                    alt="Person"
                  />
                  <h2 className="font-medium w-full">
                    {chat?.project?.title}
                  </h2>

                  <svg
                    className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </summary>
                <div className="space-y-3">
                  <span className="pt-4 bg-white px-4 leading-relaxed text-gray-700 absolute">
                    {chat.project?.description}{" "}
                  </span>
                  <p>{chat.project?.budget}</p>
                </div>
              </details>
            </div>
            <div className="flex  items-center">
              <button className="bg-white rounded-full py-2 px-4 mr-4 flex items-center">
                <IoMdPricetags className="mx-2" />
                {chat.offre?.price.toLocaleString()} €
              </button>
              <button className="bg-white rounded-full py-3 px-3 mr-4 flex items-center">
                <FaTrash />
              </button>
            </div>
          </div>
        </div>

        <div
          id="messages"
          className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
        >
          {messages?.map((message, i) => (
            <div key={i}>
              {message.user?._id !== authUser?._id ? (
                <div className="chat-message">
                  <div className="flex items-end">
                    <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                      <div>
                        <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                         {message.content}
                        </span>
                      </div>
                    </div>
                    <img
                      src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt="My profile"
                      className="w-6 h-6 rounded-full order-1"
                    />
                  </div>
                </div>
              ) : (
                <div className="chat-message">
                  <div className="flex items-end justify-end">
                    <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                      <div>
                        <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">
                          {message.content}
                        </span>
                      </div>
                    </div>
                    <img
                      src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"
                      alt="My profile"
                      className="w-6 h-6 rounded-full order-2"
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
          <div className="relative flex">
            <span className="absolute inset-y-0 flex items-center">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                  ></path>
                </svg>
              </button>
            </span>

            <textarea
              type="text"
              placeholder="votre message ici"
              className="focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3 w-full"
              value={message}
              onChange={onMessageChange}
            />
            <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  ></path>
                </svg>
              </button>

              <button
                type="button"
                className="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
                onClick={handleSubmit}
              >
                <span className="font-bold">Envoyer</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-6 w-6 ml-2 transform rotate-90"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversation;
