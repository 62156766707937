const charteQualiteText = [
  {
    mainTitle: "La Charte qualité Xxl-connexion.net",
    MainText:
      "Chaque membre professionnel adhérant à Xxl-connexion.net est tenu de respecter des normes de qualité spécifiques. Tous les professionnels associés à notre plateforme sont dûment enregistrés auprès de l'Insee.",
    items: [
      {
        subTitle: "Politique de Xxl-connexion.net",
        SubText: "Les membres professionnels de Xxl-connexion.net éprouvent une fierté envers leur travail et les relations qu'ils établissent avec leurs clients. Afin de démontrer leur engagement envers le service et la qualité, tous les professionnels souhaitant intégrer Xxl-connexion.net doivent adhérer à la politique suivante.  "
      },
      {
        subTitle: "Traitez vos clients avec respect en toutes circonstances, aussi bien sur le site internet qu'en personne.",
        SubText: `Assurez-vous de la propreté du chantier une fois les travaux achevés. Adoptez un comportement professionnel et responsable envers les particuliers, en évitant toute forme de violence physique ou verbale. Nous attachons une grande importance au respect mutuel, c'est pourquoi Xxl-connexion.net rappelle également aux particuliers leurs responsabilités`
      },
      {
        subTitle: "Respectez scrupuleusement les accords conclus et les rendez-vous fixés avec les particuliers dans toutes les circonstances. Il est impératif de : Il est important que/de:",
        SubText: `•	Être ponctuel à vos rendez-vous.
        •	Veiller à ce que le devis corresponde aux dispositions convenues.
        •	Respecter les délais sur vos chantiers.
        Nous comprenons que, en tant qu'entrepreneur, vous puissiez être occupé et que le respect des engagements puisse parfois être difficile. Cependant, il est essentiel de prévenir à l'avance vos clients en cas de contretemps, de présenter vos excuses et de convenir d'un nouveau rendez-vous.
        `
      },
      {
        subTitle: "Clarifiez le montant total du chantier ainsi que d'éventuels coûts supplémentaires, en indiquant également la date de démarrage et la durée prévue du chantier.",
        SubText: `Les particuliers recherchent la clarté et la certitude, évitez donc les imprévus. En tant qu'expert, les particuliers ont confiance en l'image que vous projetez. Communiquez clairement et réagissez rapidement en cas d'imprévus.`
      },
      {
        subTitle: "Assurez-vous de rédiger des messages professionnels qui reflètent votre intérêt.",
        SubText: `Lorsque les particuliers investissent significativement dans leur logement, ils attendent un devis professionnel. Par conséquent, veillez à rédiger dans un français compréhensible, à éviter des tarifs déraisonnablement bas, et à ne pas utiliser les frais de Xxl-connexion.net comme argument en réponse à un particulier. Vos devis doivent être clairs, mentionner la TVA et préciser les modalités de paiement.`
      },
      {
        subTitle: "Fournissez un travail de qualité, achevez les chantiers et résolvez les problèmes éventuels.",
        SubText: `La priorité absolue est de fournir un travail de haute qualité. N'acceptez pas de projets que vous ne pouvez pas réaliser. Terminez vos travaux de manière professionnelle et ne laissez aucun chantier inachevé. En cas de problème, résolvez-le correctement avec le particulier. Si un désaccord persiste, nous sommes disponibles pour vous aider à trouver un terrain d'entente.`
      },
      {
        subTitle: "Conditions :",
        SubText: `En utilisant notre site internet, vous acceptez automatiquement la Charte Qualité. En cas de plaintes, des recherches seront effectuées et en fonction de l'historique de l'entreprise, un ultime avertissement ou la suppression du compte peuvent être envisagés. Pour toute question ou commentaire, n'hésitez pas à nous contacter via : `
      },
    ],
  },
];

export default charteQualiteText;