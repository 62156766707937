import React from "react";
import "./Blog.css";
import { Link } from "react-router-dom";

const Blogcard = ({ post }) => {
  return (
    <div className="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm p-4">
      <Link className="postlink" to={`/post/${post.id}`}>
        <span className="title">{post.title}</span>
        <img src={post.img} alt="image" className="" />
        <p className="desc">{post.desc}</p>
        <button className="button">lire plus</button>
      </Link>
    </div>
  );
};

export default Blogcard;
