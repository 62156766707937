import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import UploadArtisanFile from "./UploadArtisanFile";
import Pricing from "../DashboardArtisan/Pricing";
import UploadImages from "../UploadImages/UploadImages";
import UpdatingForm from "./UpdatingForm";

const EspaceProTab = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        className="border-t-2 border-gray-300 p-2"
      >
        <Tab
          label="Accueil"
          className="bg-gray-100 py-2 px-4 font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        />
        <Tab
          label="Projets réalisés"
          className="bg-gray-100 py-2 px-4 font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        />
        <Tab
          label="avis"
          className="bg-gray-100 py-2 px-4 font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        />
      </Tabs>

      {activeTab === 0 && (
        <div>
          <UploadArtisanFile />
          <UpdatingForm/>
          <Pricing />
        </div>
      )}
      {activeTab === 1 && (
        <div>
          <UploadImages />
        </div>
      )}
      {activeTab === 2 && <div>Contenu de l'onglet 3</div>}
    </div>
  );
};

export default EspaceProTab;
