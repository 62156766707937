import React, { useEffect, useState } from "react";
import UserCard from "../../components/projetCard/UserCard";
import OfferForm from "../../components/OfferCard/OfferForm";
import { Link, useParams } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { BiTimeFive } from "react-icons/bi";
import axios from 'axios'
import { baseUrl } from "../../utils/constant_api";
import moment from 'moment'

import momentFr from 'moment-locale-fr'

const ProjectsDetailsPage = () => {
  const [project, setProjects] = useState({});


  const [isLargeImage, setIsLargeImage] = useState(false);
  const handleClick = () => {
    setIsLargeImage(!isLargeImage);
  };

  const {id} = useParams()

  const getProject = async () => {
    await axios.get(`${baseUrl}/project/${id}`)
    .then((res) => {
      console.log(res)
      setProjects(res.data)
    })
  }

  useEffect(()=>{
    getProject();
  },[]);

    const [isOffer, setIsOffer] = useState(false)

    const onOfferClick = () => {
     setIsOffer(true)
    }

  return (
    <div>
      <div className="paddings innerWidth">
        <div className="md:flex gap-3">
          <div className="mb-4 lg:mb-8 py-2 px-2 lg:w-3/4 w-full">
            <div className="p-4 mx-2 my-auto bg-white border-1 rounded-lg shadow-xl">
              <h1 className="font-bold mb-1 text-2xl">
                {project.title}
              </h1>
              <div className="flex mb-2">
                <label className="mx-2 text-xs">Ouvert</label>
                <label className="mr-2 text-xs">{project.budget}</label>
                <label className="mr-2 text-xs">42 interactions</label>
              </div>
                <p className="bg-red-700 px-8 py-2 w-60 my-2 mt-2 mb-4 text-white rounded-full flex justify-center text-sm cursor-pointer" onClick={onOfferClick}>
                  Faire une offre
                </p>
              <hr />

              <div className="details">
                <p className="my-4 mx-2">
                  {project.description}
                </p>

                <span className="flex items-center">
                  <BiTimeFive className="text-lg text-danger mx-2" />
                  &nbsp; Publié {moment(project.createdAt).fromNow()}
                </span>
                <span className="flex items-center">
                  <CiLocationOn className="text-lg text-danger mx-2" /> {project.user?.codePostal}, {project.user?.ville} (21 km)
                </span>

                <div className="w-full h-auto flex justify-start items-start flex-wrap max-h-52 overflow-y-auto mt-4">
                  <div className="w-20 h-20 gap-2 flex relative">
                    {project.images?.map ((image, i) => (
                      <img
                      key={i}
                        src={image.url}
                        alt=""
                        className="w-full h-full rounded object-cover cursor-pointer"
                        onClick={handleClick}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 py-4 lg:w-1/4">
            <span className="mb-4 font-medium">publier par:</span>
            <UserCard />
            <div className="flexCenter mt-4">
              <button className="button2">Signaler un abus</button>
            </div>
          </div>
        </div>


        {isOffer && 
            <div className="lg:w-2/3 flex justify-center w-full my-8">
                <OfferForm id = {id}/>
            </div>
        }

        <div>
          <div className="md:mx-8 lg:w-2/3 py-2 cursor-pointer">
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsDetailsPage;
