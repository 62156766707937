import React from 'react'
import "./List.css"
import  AdminSidebar from  "../../../components/AdminDashboard/AdminSidebar/AdminSidebar"
import AdminNavbar from  "../../../components/AdminDashboard/AdminNavbar/AdminNavbar"
import Datatable from "../../../components/AdminDashboard/Datatable/Datatable"

const List = () => {
  return (
    <div className='list'>
        <AdminSidebar/>
        <div className="listContainer">
            <AdminNavbar/>
            <Datatable/>

        </div>
    </div>
  )
}

export default List