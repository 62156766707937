import React, { useState } from "react";
import "./New.css";
import AdminSidebar from "../../../components/AdminDashboard/AdminSidebar/AdminSidebar";
import AdminNavbar from "../../../components/AdminDashboard/AdminNavbar/AdminNavbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import user from "../../../img/user1.png";

const New = ({ inputs, title }) => {
    const [file, setFile] = useState("");
  return (
    <div className="new">
      <AdminSidebar />
      <div className="newContainer">
        <AdminNavbar />

        <div className="newTop">
          <h1 className="newTop-title">Ajouter un nouvelle utilisateur</h1>
        </div>

        <div className="newBottom">
          <div className="new-bottomLeft">
            <img src={
                file
                ? URL.createObjectURL(file)
                : user
                } alt="" />
          </div>
          <div className="new-bottomRight">
            <form className="newForm">
              <div className="newFormInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="newFormInput" key={input.id}>
                  <label>{input.label}</label>
                  <input type={input.type} placeholder={input.placeholder} />
                </div>
              ))}
              <br/>
              <button>Envoyer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
