import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'

const PrivateRoutes = () => {
    let auth = localStorage.getItem('token');
  return (
    <div>
       {auth !== null && auth !== undefined ?  <Outlet/> : <Navigate to ='/connexion'/>}
    </div>
  )
}

export default PrivateRoutes
