import React from 'react'
import "./Widget.css"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { green, lightGreen } from '@mui/material/colors';

const Widget = ({type}) => {

const amout = 100 
const diff = 20  

  let data; 

    switch(type){
      case "user": 
      data= {
        title: "USERS",
        isMoney: false,
        link: "voir tout les utilisateurs",
        icon: <AccountCircleIcon className='widget-icon'/>
      };
      break; 
      case "artisans": 
      data= {
        title: "ARTISANS",
        isMoney: false,
        link: "voir tout les artisans",
        icon: <ManageAccountsIcon className='widget-icon' style={{backgroundColor: "green", color:"white"}}/>
      };
      break; 
      case "projets": 
      data= {
        title: "PROJETS",
        isMoney: false,
        link: "voir tout les projets",
        icon: <AssuredWorkloadIcon className='widget-icon' style={{backgroundColor: "blue", color:"white"}}/>
      };
      break;
      case "paiements": 
      data= {
        title: "BALANCE",
        isMoney: true,
        link: "voir toutes les transactions",
        icon: <MonetizationOnIcon className='widget-icon' style={{backgroundColor: "pink", color:"white"}}/>
      };
      break;
      default:
        break;
    }



  return (
    <div className="widget">
          <div className="widget-left">
            <span className="widget-title">{data.title}</span>
            <span className="widget-counter">{data.isMoney && "$"} {amout}</span>
            <span className="widget-link">{data.link}</span>
          </div>
          <div className="widget-right">
            <div className="percentage positive">
              {<KeyboardArrowUpIcon/>}
              {diff}
            </div>
            {data.icon}
          </div>
    </div>
  )
}

export default Widget