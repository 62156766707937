import React from 'react'
import "./Post.css"
import { posts } from '../../components/Blog/dataBlog'
import { useLocation } from 'react-router-dom';

const Post = () => {
const location = useLocation() 
const path = location.pathname.split("/")[2]

const post = posts.find((p) => p.id.toString() === path)

  return (
    <section className="P-wrapper">
        <div className="innerWidth paddings p-container">
            <div className="post">
            <img src={post.img} alt="" className='post-img'/>
            <h1 className="post-title">{post.title}</h1>
            <p className="post-long-desc">{post.longDesc}</p>
            </div>
        </div>

    </section>
  )
}

export default Post