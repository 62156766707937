import { useEffect } from "react";
import axios from "axios";
import { geoUrl } from "../../../utils/constant_api";

const PersonalInformations = ({ userData, setUserData, errors }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    axios.get(`${geoUrl}=${userData.codePostal}`).then((res) => {
      if (res.status === 200 && res.data.length > 0) {
        setUserData({ ...userData, ville: res.data[0].nom });
      }
    });
  }, [userData.codePostal]);

  return (
    <div className="mt-8 grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="prenom"
          className="block text-sm font-medium text-gray-700"
        >
          Prénom :
        </label>
        <input
          onChange={handleChange}
          value={userData["prenom"] || ""}
          type="text"
          id="prenom"
          name="prenom"
          placeholder="Prénom"
          className={`mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2 ${
            errors.prenom ? "border-red-600 border-2" : ""
          } `}
        />
        {errors.prenom && (
          <span className="text-red-600 text-xs mx-1">{errors.prenom}</span>
        )}
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="nom"
          className="block text-sm font-medium text-gray-700"
        >
          Nom :
        </label>

        <input
          onChange={handleChange}
          value={userData["nom"] || ""}
          type="text"
          id="nom"
          name="nom"
          placeholder="Nom :"
          className={`mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2 ${
            errors.nom ? "border-red-600 border-2" : ""
          } `}
        />
        {errors.nom && (
          <span className="text-red-600 text-xs mx-1">{errors.nom}</span>
        )}
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label
          htmlFor="adresse"
          className="block text-sm font-medium text-gray-700"
        >
          Adresse :
        </label>

        <input
          onChange={handleChange}
          value={userData["adresse"] || ""}
          type="adress"
          id="adresse"
          name="adresse"
          placeholder="Adresse"
          className={`mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2 ${
            errors.adresse ? "border-red-600 border-2" : ""
          } `}
        />
        {errors.adresse && (
          <span className="text-red-600 text-xs mx-1">{errors.adresse}</span>
        )}
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label
          htmlFor="codePostal"
          className="block text-sm font-medium text-gray-700"
        >
          Code Postal :
        </label>

        <input
          onChange={handleChange}
          value={userData["codePostal"] || ""}
          type="number"
          id="codePostal"
          name="codePostal"
          placeholder="Code postal"
          className={`mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2 ${
            errors.codePostal ? "border-red-600 border-2" : ""
          } `}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <label
          htmlFor="ville"
          className="block text-sm font-medium text-gray-700"
        >
          Ville :
        </label>

        <input
          onChange={handleChange}
          value={userData["ville"] || ""}
          type="text"
          id="ville"
          name="ville"
          placeholder="Ville"
          className={`mt-1 w-full rounded-md border-gray-200 border-1 bg-white text-sm text-gray-700 shadow-sm p-2 ${
            errors.ville ? "border-red-600 border-2" : ""
          } `}
        />
      </div>
    </div>
  );
};

export default PersonalInformations;
