import {Outlet} from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Layout = ({categories, authUser}) => {

  return (<div>
        <Header categories = {categories} authUser = {authUser}/>
        <Outlet/>
        <Footer/>
    </div>
  )
}

export default Layout