import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from '../../config/swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import "./TopCategories.css"
import { sliderSettings } from '../common'


const TopCategories = ({categories}) => {
  const SliderButtons = () => {
    const swiper = useSwiper();
    return (
      <div className="flexCenter  s-button">
        <button onClick={()=> swiper.slidePrev()}>&lt;</button>
        <button onClick={()=> swiper.slideNext()}>&gt;</button>
      </div>
    );
  };

  const goToCompanies = (e) => {
    window.location.href="/companies"
  }

  
  return (
    <section className="t-wrapper">
        <div className="paddings innerWidth t-container position-relative">
            <div className="t-head flexColStart">
                <span className='primaryText'>Top catégories</span>
            </div>

            <Swiper {...sliderSettings}>
            <SliderButtons/>
                {
                    categories?.map((categorie, i)=> (
                        <SwiperSlide key={i}>
                            <div className="flexColStart tc-card align-items-center">
                                 <img src={categorie.images[0]?.url} alt="image"/>
                                  
                                 <span className="primaryText">{categorie.name}</span>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>

        </div>
    </section>
  )
}
export default TopCategories

