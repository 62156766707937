import axios from "axios";
import { baseUrl } from "./utils/constant_api";

export const getMe = async (setAuthUser) =>{
    const token = localStorage.getItem('token');
    if (token) {
      await axios.get(`${baseUrl}/auth/me`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      })
      .then((response) => {
      if (response.data.status === "ok") {
        setAuthUser(response.data.user);
        localStorage.setItem('userRole', response.data.user.role)
      }else{
        localStorage.removeItem('token');
      }
    }).catch((error) => {
      localStorage.removeItem('token');
      window.location.href="/";
    })
    }
  }

 export const populateCategorie = async (setCategorie) => {
     await axios.get(`${baseUrl}/categories`)
    .then((res) => 
      {
        setCategorie(res.data)
      }
    );
  }