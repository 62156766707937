import React from "react";
import "./Datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "../Datatable/datatablesource";
import {Link} from "react-router-dom"



const Datatable = () => {

  const actionColumn = [
    {field: "action", hearderName: "Action", width: 200, 
    renderCell: () => {
      return (
        <div className="cellAction">
        <Link to = "/admin/users/test">
          <div className="viewButton">View</div>
        </Link>
          <div className="deleteButton">Delete</div>
        </div>
      )
    }
    }
  ]

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Ajouter un nouvel utilisateur
        <Link to = "/admin/users/new" className="link">Nouveau</Link>
      </div>
      <DataGrid
        rows={userRows}
        columns={userColumns.concat(actionColumn)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
