import React, { useState, useEffect } from "react";

const Stepper = ({ steps, currentStep }) => {
  const [stepStates, setStepStates] = useState([]);

  useEffect(() => {
    const newSteps = steps.map((step, index) => ({
      description: step,
      completed: index < currentStep,
      highlighted: index === currentStep - 1,
      selected: index <= currentStep - 1,
    }));
    setStepStates(newSteps);
  }, [steps, currentStep]);

  const displaySteps = stepStates.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index != stepStates.length - 1
            ? "w-full flex items-center"
            : "flex items-center"
        }
      >
        <div className="relative flex flex-col items-center text-red-600 justify-around">
          <div
            className={`rounded-full transition duration-500 ease-in-out border border-gray-300 h-12 w-12 flex items-center justify-center py-3 ${
              step.selected
                ? "bg-red-600 text-white font-bold border border-red-600 "
                : ""
            }`}
          >
            {step.completed ? (
              <span className="text-white font-bold text-xl">&#10003;</span>
            ) : (
              index + 1
            )}
          </div>
          <div
            className={`absolute top-0 text-center font-medium mt-16 w-32 text-xs gap-4 uppercase  ${
              step.highlighted ? "text-gray-900 " : "text-gray-400 hidden md:block"
            }`}
          >
            {step.description}
          </div>
        </div>
        <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${step.completed ? "border-red-600" : "border-gray-400"}`}
        >
          {/* Display line */}
        </div>
      </div>
    );
  });

  return (
    <div className="mx-4 p-4 flex justify-between items-center">
      {displaySteps}
    </div>
  );
};

export default Stepper;
