import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";

const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    console.log("initialisation du payment")

    if(!stripe || !elements){
        return;
    }

    setIsProcessing(true)
    const {error, paymentIntent} = await stripe.confirmPayment({
        elements, 
        confirmParams: {
            return_url:`${window.location.origin}/succedpayment`,
        // redirect: "if_required"
        },
    }); 

    if(error) {
        setMessage(error.message); 
    }else if (paymentIntent && paymentIntent.status ==="succeeded"){
        setMessage("payment status: " + paymentIntent.status + "yppp");
    }else {
        setMessage("Unexpected state");
    }
    setIsProcessing(false); 
  };

  return (
    <div className="paddings innerWidth flexCenter">
      <form id="payment-form" onSubmit={() => handleSubmit()} className="border rounded-lg p-4 w-full lg:w-3/4">
        <PaymentElement />
        <button className="px-4 py-2 bg-red-600 cursor-pointer rounded-lg text-white my-4 flex mx-auto" disabled = {isProcessing} id="submit">
            <span>
                {isProcessing ? "Traitement": "Payer"}
            </span>
        </button>

        {message && <div>{message }</div>}
      </form>
    </div>
  );
};

export default StripeForm;
