import { Outlet } from "react-router-dom";
import React from "react";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import Sidebar from "./Sidebar";

const ClientLayout = ({ authUser }) => {
  return (
    <div className="flex flex-wrap">
      <div className="">
        <Sidebar authUser={authUser} />
      </div>
      <div className="flex-1 lg:ml-80 w-full">
        <Outlet />
      </div>
      {/* <LeftSide authUser = {authUser}/> */}
      {/* <RightSide authUser = {authUser}/> */}
    </div>
  );
};

export default ClientLayout;
