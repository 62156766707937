import { useState, createContext } from "react";

const FormContext = createContext()

export const FormProvider = ({children})=> {
  const [entreprisename, setEntrepriseName] = useState('');
  const [siret, setSiret] = useState('');
  const [ville, setVille] = useState('');
  const [metier, setMetier] = useState('');
  const [sexe, setSexe] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <FormContext.Provider
    value={{
        entreprisename, 
        siret, 
        ville,
        metier,
        sexe,
        nom,
        prenom,
        phone,
        email,
        password,
        setEntrepriseName,
        setSiret,
        setVille,
        setMetier,
        setSexe,
        setNom,
        setPrenom,
        setPhone,
        setEmail,
        setPassword,
    }}
    >
        {children}
    </FormContext.Provider>
)
  
}

export default FormContext;