import React from "react";
import charteQualiteText from "./charteQualite";

const CharteQualité = () => {
  return (
    <div className="paddings innerWidth">
      {charteQualiteText.map((item, index) => (
        <div className="paddings flexCenter">
          <div key={index}>
            <h1 className="text-3xl font-bold p-2 m-2">{item.mainTitle}</h1>
            <p className="p-2 m-2 text-lg">{item.MainText}</p>
          </div>
          {item.items.map((el, i) => (
            <div key={i}>
              <h1 className="text-2xl font-bold p-2 m-2">{el.subTitle}</h1>
              <p className="p-2 m-2 text-lg">{el.SubText}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CharteQualité;
