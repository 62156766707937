import React from "react";

const OfferCard = () => {
  return (
    <div className="px-3 border-1 border py-3 rounded-lg bg-white shadow-xl mb-4 w-full">
      <div className="mx-4 mb-2">
        <p className="font-medium text-gray-500">Offre déposé le 30/11/2023</p>
      </div>
      <div className="flex items-center gap-2 mx-4 justify-between">
        <div className="flex">
          <div className="rounded-full  bg-red-600 w-16 h-16 flex justify-center items-center border-4 border-gray-700 ">
            <p className="font-bold text-2xl text-white">L</p>
          </div>
          <div className="mx-2">
            <p className="societé font-medium text-gray-700">
              Les Batisseurs de Royaumes
            </p>
            <p className="location text-bse ml-2">Nante</p>
            <p className="statut font-bold text-base text-green-700">Vérifié</p>
          </div>
        </div>
        <div className="">
          <label className="text-sm text-gay-500">il y'a 6 jour</label>
          <p className="text-sm text-gay-500">Montant masqué</p>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
