import React from 'react'
import ArtisanRegisterForm from '../../components/Form/ArtisanRegisterForm'

const RegisterArtisan = () => {
  return (
    <div className='py-4 bg-[#f1f5f9]'>
      <ArtisanRegisterForm />
    </div>
  )
}

export default RegisterArtisan
