import React, { useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, geoUrl } from "../../utils/constant_api";
import "./PublishProject.css";
import { TiDelete } from "react-icons/ti";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { LinearProgress, Button, Typography, TextField, Container, Radio, RadioGroup,
FormControlLabel, FormControl, Checkbox, Switch, CircularProgress, Dialog, } from "@mui/material";

const steps = [
  "Informations de base",
  "votre projet",
  "plus de details",
  "email",
  "Finalisation",
];

const PublishProject = ({authUser}) => {
  console.log(authUser)
  // const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const [locations, setLocations] = useState([]);
  const totalSteps = 6;
  const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(true);
  const [switchCked, setSwitchChed] = useState(false);

  const [images, setImages] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const [cityName, setCityName] = useState("");
  const [ville, setVille] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState("");
  const [delay, SetDelay] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [codePostal, setcodePostal] = useState("");
  const [adresse, setAdresse] = useState("");

  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
    const files = event.target.files;
  
    if (files.length === 0) return;
  
    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
  };

  const deleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i != index));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    const imageFiles = [];
    for (let i = 0; i < files?.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSwitchChange = (event) => {
    setSwitchChed(event.target.checked);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlertMessage("Création du projet en cours...");
    const token = localStorage.getItem("token");
    const ville = cityName
    axios
      .post(
        `${baseUrl}/project/new`,
        {
          adresse,
          codePostal,
          ville,
          title,
          description,
          budget,
          delay,
          message,
          categorie: localStorage.getItem("cat_project"),
          secteur: localStorage.getItem("sec_project"),
        },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setAlertMessage("Ajout des images en cours...");
          const newFormData = new FormData();
          for (let i = 0; i < myFiles?.length; i++) {
            newFormData.append(`images`, myFiles[i]);
          }
          axios
            .put(
              `${baseUrl}/project/upload/${res.data.project._id}`,
              newFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "x-access-token": `${token}`,
                },
              }
            )
            .then((response) => {
              console.log(response)
              setLoading(false);
              setOpen(true);
            });
        } else {
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
      ;
  };

  const goToDashboard = () => {
    setOpen(false);
    navigate("/client-monespace");
  };

  useEffect(() => {
    axios.get(`${geoUrl}=${codePostal}`).then((response) => {
      if (response.status === 200 && response.data.length != 0) {
        setCityName(response.data[0].nom);
      } else {
        setCityName("");
      }
    });
  }, [codePostal]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 p-4">
              <h2 className="primaryText">
                Décrivez votre Projet et échangez avec des artisans proche de
                chez vous
              </h2>
            </div>
            <label className="mb-3 font-bold text-xl flex justify-center">
              adresse du lieu des travaux
            </label>
            <div className="mb-3 md:flex justify-center md:mx-4 mt-2">
              <TextField
                label="adressee N° Rue"
                className="mx-2 mb-2 w-11/12 md:w-1/3"
                name="adresse"
                onChange={(e) => setAdresse(e.target.value)}
                value={adresse}
              />
              <TextField
                label="Code Postal"
                className="mx-2 mb-2 w-11/12 md:w-1/3"
                type="number"
                name="codePostal"
                onChange={(e) => setcodePostal(e.target.value)}
                value={codePostal}
              />
              {cityName && (
                 <TextField
                 className="mx-2 mb-2 w-11/12 md:w-1/3"
                 label= {cityName}
                 disabled
               />
              )}
            </div>
          </div>
        );
      case 1:
        return (
          <div className="my-2 sm:p-2 md:p-6">
            <label className="mb-3 font-bold text-xl">
              Quel est votre projet
            </label>
            <div>
              <TextField
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                label="Quels travaux souhaiter vous éffectuer"
                fullWidth
              />
            </div>
            <label className="my-3 font-bold text-xl">
              Décrivez vos travaux
            </label>
            <div>
              <TextField
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                label="Description des travaux à éffectuer"
                multiline
                rows={6}
                fullWidth
              />
            </div>
            <label className="mt-3 font-bold text-xl">Delai</label>
            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="delay"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="Urgent"
                    onChange={(e) => SetDelay(e.target.value)}
                    label="Urgent"
                  />
                  <FormControlLabel
                    value="Pas Fixe"
                    control={<Radio />}
                    label="Pas Fixe"
                    onChange={(e) => SetDelay(e.target.value)}
                  />
                  <FormControlLabel
                    value="Dans moins de deux semaines"
                    control={<Radio />}
                    label="Dans moins de deux semaines"
                    onChange={(e) => SetDelay(e.target.value)}
                  />
                  <FormControlLabel
                    value="Dans moins d'un mois"
                    control={<Radio />}
                    label="Dans moins d'un mois"
                    onChange={(e) => SetDelay(e.target.value)}
                  />
                  <FormControlLabel
                    value="Dans moins de six mois"
                    control={<Radio />}
                    label="Dans moins de six mois"
                    onChange={(e) => SetDelay(e.target.value)}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="md:p-6">
            <label className="my-3 font-bold text-xl">Votre Budget</label>
            <div className="mb-2">
              <TextField
                type = "number"
                label="Budget en €"
                name="budget"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </div>

            <label className="my-3 font-bold text-xl">
              Votre message pour les artisans
            </label>
            <div className="mb-2">
              <TextField
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Votre message pour les artisans"
                multiline
                rows={6}
                fullWidth
              />
            </div>
            <div className="mb-2">
              <div className="flex">
                <label>Ajouter des images </label>
                <Switch
                  checked={switchCked}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              {switchCked && (
                <div>
                  <div className="upload-box">
                    <div
                      className="drag-area"
                      onDragOver={onDragOver}
                      onDragLeave={onDragLeave}
                      onDrop={onDrop}
                    >
                      {isDragging ? (
                        <span className="select">Ajoutez des images ici</span>
                      ) : (
                        <>
                          Faites glisser vos images ici ou{" "}
                          <span
                            className="select"
                            role="button"
                            onClick={selectFiles}
                          >
                            {" "}
                            pacourir
                          </span>
                        </>
                      )}
                      <input
                        type="file"
                        className="file"
                        multiple
                        ref={fileInputRef}
                        onChange={onFileSelect}
                      />
                    </div>

                    <div className="container">
                      {images.map((images, indexKey) => (
                        <div className="image" key={indexKey}>
                          <span onClick={() => deleteImage(indexKey)}>
                            <TiDelete className="delete" />
                          </span>
                          <img src={images.url} alt={images.name} />
                        </div>
                      ))}
                    </div>
                    {/* <button type="submit" onClick={uploadImages}>ajouter</button> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <label className="my-3 font-bold text-xl">adressee Mail</label>
            <div className="mb-2">
              <TextField
                label="Votre adressee Mail"
                name="email"
                value={authUser ? authUser.email : email}
                onChange={(e) => setEmail(e.target.value)}
                className="mx-2"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="md:p-6">
            <div className="my-2">

              <div className="flex justify-center">
              <label className="my-3 font-bold text-2xl ">
                Veuillez créer un compte pour suivre votre projet
              </label>
              </div>
              <label className="mt-2 text-xl">E-mail</label>
              <TextField
                label={authUser ? authUser.email : email}
                disabled
                fullWidth
              />
            </div>

            <div className="my-2">
              <label className="mt-2 text-xl">Nom</label>
              <TextField
                id="outlined-multiline-static"
                label="Votre nom"
                name="name"
                value={authUser ? authUser.name : name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </div>

            <div className="my-2">
              <label className="mt-2 text-xl">Téléphone</label>
              <TextField
                id="outlined-multiline-static"
                label="Télephone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              />
            </div>
            <div className="my-4 flex justify-center items-center">
              <Checkbox
                checked={checked}
                onChange={handleCheckChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="caption" display="flex" gutterBottom>
                je souhaite recevoir des e-mails occasionnels concernant les
                nouveaux produits, les promotions et les autres nouveautés
              </Typography>
            </div>

            <Dialog
              open={open}
              // onClose={handleClose}
            >
              <div className="p-4">
                <IoCheckmarkDoneCircle className="w-16 h-16 text-green-500 mx-auto" />
                <div className="flex justify-center items-center">
                  <p className="text-lg font-medium mx-auto">
                    Creation du projet terminé avec sucess{" "}
                  </p>
                </div>
                <p className="mb-2">
                  Connectez vous à votre espace pour suivre votre projet.
                </p>
                <div className="flex justify-center">
                  <button className="button" onClick={goToDashboard}>
                    continuer
                  </button>
                </div>
              </div>
            </Dialog>
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div className="paddings flexCenter">
      <Container>
        <label className="mb-3 font-bold text-xl">
          étape {currentStep} sur {totalSteps}{" "}
        </label>
        <div className="mb-3">
          <LinearProgress variant="determinate" value={(activeStep + 1) * 20} />
        </div>

        <div className="boder-1 rounded-lg shadow-lg p-4">
          {activeStep === steps.length ? (
            <div>
              <Typography>Toutes les étapes sont complétées</Typography>
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
              <div>
                {loading ? (
                  <div className="flex mx-4 items-center">
                    <CircularProgress />
                    <p className="mx-4">{alertMessage}</p>
                  </div>
                ) : (
                  <div className="flexEnd">
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Retour
                    </Button>
                    <Button
                      variant="contained"
                      onClick={
                        activeStep === steps.length - 1 ? onSubmit : handleNext
                      }
                      color="error"
                    >
                      {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default PublishProject;
