import React, { useState, useRef } from "react";
import "./UploadImages.css";
import { TiDelete } from "react-icons/ti";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";

const UploadImages = () => {
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState(null);

  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
    const files = event.target.files;
    if (files.length == 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  };

  const deleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i != index));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  };

  const uploadImages = () => {
    const formData = new FormData();
    for (i = 0; i < files.length; i++) {
      formData.append(`file${i + 1}`, files[i]);
    }
    axios.put(`${baseUrl}/project/upload/${res.data.project._id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${token}`,
      },
    });
  };

  return (
    <div>
      <div className="upload-box">
        <div
          className="drag-area"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          {isDragging ? (
            <span className="select">Ajoutez des images ici</span>
          ) : (
            <>
              Faites glisser vos images ici ou{" "}
              <span className="select" role="button" onClick={selectFiles}>
                {" "}
                pacourir
              </span>
            </>
          )}
          <input
            type="file"
            className="file"
            multiple
            ref={fileInputRef}
            onChange={onFileSelect}
          />
        </div>

        <div className="container">
          {images.map((images, index) => (
            <div className="image" key={index}>
              <span onClick={() => deleteImage(index)}>
                <TiDelete className="delete" />
              </span>
              <img src={images.url} alt={images.name} />
            </div>
          ))}
        </div>
        <button type="submit" onClick={uploadImages}>
          ajouter
        </button>
      </div>
    </div>
  );
};

export default UploadImages;
