import React, { useRef, useState } from "react";
import { RiFolderUploadFill } from "react-icons/ri";
import "./Uploadfile.css"; 
import axios from 'axios'

const UpdatingForm = () => {
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showProgress, setShowProgress] = useState([]);
  const fileInputRef = useRef(null);
  const [site, setsite] = useState("");
  const [presentation, setPresentation] = useState("");

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const uploadFile = (event) => {
    const file = event.target.files[0]
    if(!file) return ; 
    const fileName = file.name.length > 12 
    ? `${file.name.substring(0, 13)}...${file.name.split('.')[1]}`
    :file.name;
    const formData = new FormData(); 
    formData.append('file', file)
    setFiles(prevState => [...prevState, {name: fileName, loading: 0}])
    setShowProgress(true);
      axios.post('', {formData}, {
        onUploadProgress: ({loaded, total}) => {
          setFiles(prevState => {
            const newFiles = [...prevState]; 
            newFiles[newFiles.length -1].loading  = Math.floor((loaded / total) * 100);
            return newFiles; 
          });
          if(loaded == total) {
            const fileSize  = total < 1024
            ?`${total}KB` 
            :`${(loaded / (1024*1024)).toFixed(2)} MB`
            setUploadedFiles([...uploadedFiles, {name: fileName, size: fileSize}])
            setFiles([]);
            setShowProgress(false); 
          }
        },
      }).catch(console.error)
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="gap-x-16 gap-y-8">
        <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12 max-w-screen-sm mx-auto">
          <form action="" className="space-y-4">
            <div className="flexCenter mb-3 p-2">
              <h1 className="font-medium text-lg">
                Veuillez completer les informations de votre entrprise !{" "}
              </h1>
            </div>

            <div className="uplaod-box">
              <div className="upload-form">
                <input
                  type="file"
                  name="file"
                  className="file-input"
                  hidden
                  ref={fileInputRef}
                  onChange={uploadFile}
                />
                <div className="icon" onClick={handleFileInputClick}>
                  <RiFolderUploadFill size="50" className="" />
                </div>
                <p>Parcourir pour ajouter vos fichier</p>
              </div>
              {showProgress && (
                <section className="loading-area">
                  {files.map((file, i) => {
                    <li
                      className="p-2 flex justify-center items-center bg-blue-200"
                      key={i}
                    >
                      <i className="fas fa-file-alt"></i>
                      <div className="content">
                        <div className="details">
                          <span className="name">
                            {`${file.name} -telechargement`}
                          </span>
                          <span className="percent">{`${file.loading}`}</span>
                          <div className="loading-bar">
                            <div
                              className="loading"
                              style={{ width: `${file.loading}%` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </li>;
                  })}
                </section>
              )}

              <section className="uploaded-area">
                {uploadedFiles.map((file, index) => (
                  <li className="row flex justify-between" key={index}>
                    <div className="content upload">
                      <i className="fas fa-file-alt"></i>
                      <div className="details">
                        <span className="name">{file.name}</span>
                        <span className="size">{file.size}</span>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-check"></i>
                    </div>
                  </li>
                ))}
              </section>
            </div>
            <div>
              <label className="m-2" htmlFor="name">
                Avez vous un site web ?{" "}
              </label>
              <input
                className="w-full rounded-lg border-1 border-gray-600 p-3 text-sm"
                placeholder="lien vers votre site web."
                type="text"
              />
            </div>

            <div>
              <label className="m-2" htmlFor="message">
                Ajoutez une description de vous ou de votre entreprise
              </label>

              <textarea
                className="w-full rounded-lg border-1 border-gray-200 p-3 text-sm"
                placeholder="faites une description de votre entreprise et/ou de vos services"
                rows="8"
                id="message"
              ></textarea>
            </div>

            <div className="mt-4">
              <button
                type="submit"
                className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatingForm;
