import React, { useEffect, useState } from "react";

const Clientstats = () => { 

  return (
    <div>
      <section className="bg-white w-full">
        <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 md:py-16 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              BIENVENUE SUR XXL-CONNEXION 
            </h2>

            <p className="mt-4 text-gray-500 sm:text-xl">
            Xxl-connexion.com est la suite logique de devis-travaux-gratuits.com créé par XXL WEB DESIGN CREATION qui a été fondée en 2019.
            </p>
          </div>

          <div className="mt-8 sm:mt-12">
            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div className="flex flex-col rounded-lg bg-blue-100 px-4 py-8 text-center">
                <dt className="order-last text-xl font-medium text-gray-500">
                  Projets par an
                </dt>

                <dd className="text-3xl font-extrabold text-blue-600 md:text-5xl">
                  500.000
                </dd>
              </div>

              <div className="flex flex-col rounded-lg bg-blue-100 px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Artisans
                </dt>

                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                  25 000
                </dd>
              </div>

              <div className="flex flex-col rounded-lg bg-blue-100 px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Avis
                </dt>

                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                35 000
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clientstats;
