import React, { useState } from "react";
import { Form, Steps, Button, Input, Checkbox, Space, Select } from "antd";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";



const {Option} = Select;
const { Step } = Steps;


const CustomForm = ({ categories }) => {
  const [categorieSelected, SetCategorieSelected] = useState([]);
  const [myForm] = Form.useForm();

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const handleFormSubmit = (values) => {
    setFormData({ ...formData, ...values });
    setCurrentStep(currentStep + 1);
    let categorieChoose = myForm.getFieldValue('categories');
    let newCategorie = [];
    
     categories.forEach(element => {
      categorieChoose.forEach(elt =>{
        if (element._id == elt) {
          newCategorie.push(element);
        }
      });
     });
     SetCategorieSelected(newCategorie);
  };

  const handleFormBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFormFinish = () => {

    axios.post(`${baseUrl}/artisan/create`, formData)
      .then(response => {
        console.log('Form submitted successfully!', response.data);
        setFormData({});
        localStorage.setItem('email', email)
        window.location.href = "/confirm";
      })
      .catch(error => {
        console.error('Error submitting form:', error);
      });
  };


  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };


  return (
    <div className="">
      <Steps current={currentStep}>
        <Step title="Informations Personnelles" className = ''/>
        <Step title="Informations Professionnelles" />
        <Step title="Identifiants" />
        <Step title="Summary" />
      </Steps>
      <Form form = {myForm}
        className = "w-full"
        name="custom-form"
        onFinish={handleFormSubmit}
        initialvalues={formData}
      >
        {currentStep === 0 && (
          <div>
            <Form.Item name="prenom" label="Prénom"  className="pt-3">
              <Input />
            </Form.Item>

            <Form.Item name="nom" label="Nom" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Space direction="horizontal">
              <Form.Item
                name="adresse"
                label="Adresse"
                rules={[{ required: true }]}
              >
                <Input placeholder="N° Rue" />
              </Form.Item>
              <Form.Item
                name="ville"
                label="Ville"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="codePostal"
                label="Code Postal"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Space>

            <Form.Item
              name="telephone"
              label="Téléphone"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Button type="primary" htmlType="submit" className="button">
              suivant
            </Button>
          </div>
        )}

        {currentStep === 1 && (
          <div>
            <Form.Item
              name="denomination"
              label="Dénomination"
              rules={[{ required: false }]}
              className="pt-3"
            >
              <Input placeholder="si société" />
            </Form.Item>

            <Form.Item name="siret" label="Siret" rules={[{ required: true }]}>
              <Input placeholder="N° Siret" />
            </Form.Item>

            <Form.Item name="D1" label="D1" rules={[{ required: true }]}>
              <Input placeholder="inscription à la chambre des métiers" />
            </Form.Item>

            <Form.Item
              name="categories"
              label="Categories"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="selectionnez une catégories"
                initialvalues={[]}
                onChange={handleChange}
                optionLabelProp="label"
              >
                {categories?.map((categorie, i) => (
                  <Option label={categorie.name} key={i} value={categorie._id}>
                    <Space>{categorie.name}</Space>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Button type="primary" htmlType="submit" className="mx-2 button">
              Next
            </Button>
            <Button onClick={handleFormBack}>Précédent</Button>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <Form.Item name="email" label="Email"  rules={[{ required: true }]} className="pt-3">
              <Input />
            </Form.Item>

            <Form.Item
              name="pass"
              label="Password"
              rules={[
                { required: true, message: "Entrez votre mot de passes" },
                {
                  pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message: `Minimum de huit caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial`,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirmPass"
              label="Confirm Password"
              dependencies={["pass"]}
              rules={[
                { required: true, message: "Confirmez votre mot de passes" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("pass") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Les mots de passe ne correspondent pas")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="gap-15">
              Next
            </Button>
            <Button onClick={handleFormBack}>Back</Button>
          </div>
        )}

        {currentStep === 3 && (
          <div className="flexColStart">
            <p className="pt-3">Prénom: {formData.prenom}</p>
            <p>Nom: {formData.nom}</p>
            <p>Email: {formData.email}</p>
            <p>Categories: {categorieSelected.map((item, i) => <span key={i} className="badge bg-danger mx-1">{item.name}</span> )} </p>
            <p>Password: ********</p>
            <div>

            <Button onClick={handleFormFinish} className="button mx-2">
              Submit
            </Button>
            <Button onClick={handleFormBack}>Back</Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default CustomForm;
