import React, { useState } from "react";
import Stepper from "../Stepper/Stepper";
import StepperControl from "../Stepper/StepperControl";
import PersonalInformations from "../Stepper/Steps/PersonalInformations";
import ProfessionalInformation from "../Stepper/Steps/ProfessionalInformation";
import Account from "../Stepper/Steps/Account";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { CircularProgress } from "@mui/material";

const ArtisanRegisterForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const steps = [
    "Informations Personnelles",
    "Informations Professionnelles",
    "Identifiants",
  ];

  function submitData() {
    setIsLoading(true)
    axios.post(`${baseUrl}/artisan/create`, userData)
    .then((response) => {
      console.log(response)
      if(response.status === 201) {
        localStorage.setItem('email', userData.email)
        window.location.href = "/confirm";
      } else {
        throw new Error;
      }
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
    })
    .finally(() => {
      setIsLoading(false)
    })
    ;
  }

  const validatePersonalInformations = (userData) => {
    const errors = {};

    if (!userData.prenom) errors.prenom = "Le prénom est requis";
    if (!userData.nom) errors.nom = "Le nom est requis";
    if (!userData.adresse) errors.adresse = "L'adresse est requise";
    if (!userData.codePostal) errors.codePostal = "Le code postal est requis";
    if (!userData.ville) errors.ville = "La ville est requise";

    return errors;
  };

  const handleClick = (direction) => {
    let newStep = currentStep;
    if (direction === "next") {
      if (currentStep === 1) {
        const stepErrors = validatePersonalInformations(userData);
        if (Object.keys(stepErrors).length > 0) {
          setErrors(stepErrors);
          return; // Ne passe pas à l'étape suivante si des erreurs sont présentes
        }
      }
      setErrors({});
      newStep++;
    } else {
      newStep--;
    }

    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <div className="container flex justify-center">
      {isLoading ? (
        <div className="loader">
          <CircularProgress size={70} />
        </div>
      ) : (
        <div className="shadow-xl rounded-2xl pb-2 bg-white w-full max-w-3xl ">
          {/* Stepper */}
          <div className=" horizontal mt-5">
            <Stepper steps={steps} currentStep={currentStep} />
            {/* display stepper component */}
            <div className="my-10 p-10">
              {currentStep === 1 && (
                <PersonalInformations
                  userData={userData}
                  setUserData={setUserData}
                  errors={errors}
                />
              )}
              {currentStep === 2 && (
                <ProfessionalInformation
                  userData={userData}
                  setUserData={setUserData}
                />
              )}
              {currentStep === 3 && (
                <Account userData={userData} setUserData={setUserData} />
              )}
            </div>
          </div>

          {/* StepperControls */}
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            submitData={submitData}
            setIsLoading = {setIsLoading}
            steps={steps}
          />
        </div>
      )}
    </div>
  );
};

export default ArtisanRegisterForm;
