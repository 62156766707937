import React from "react";
import Ville from "../components/ville/Ville";
import Blog from "../components/Blog/Blog";
import Hero from "../components/Hero/Hero";
import TopCategories from "../components/TopCategories/TopCategories";
const Home = ({categories}) => {
  return (
    <div>
     <Hero categories = {categories}/>
     <TopCategories categories = {categories}/>
     <Blog/>
    </div>
  );
};

export default Home;
