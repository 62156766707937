import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
const ArtisanPrivateRoutes = () => {
  let token = localStorage.getItem("token");
  let role = "role";


    if (token !== null && token !== undefined) {
      role = localStorage.getItem("userRole");
    } else {
      window.location.href = "/connexion";
    }


  return <div>{role !== "artisan" ? <Navigate to="/" /> : <Outlet />}</div>;
};

export default ArtisanPrivateRoutes;
