import React from 'react'
import {Outlet} from "react-router-dom";
import ArtisanHeader from './ArtisanHeader';

const ArtisanLayout = ({authUser}) => {
  return (
    <div>
        <ArtisanHeader authUser = {authUser}/>
        <Outlet/>
    </div>
  )
}

export default ArtisanLayout