import React from 'react'
import "./ClientDashboard.css"
import LeftSide from '../../components/DashboardClient/LeftSide'
import MainSide from '../../components/DashboardClient/MainSide'
import RightSide from '../../components/DashboardClient/RightSide'

const ClientDashboard = ({categories, authUser}) => {
  return (
   <div>
    <MainSide categories = {categories} authUser = {authUser}/>
   </div>
  )
}

export default ClientDashboard  