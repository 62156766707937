import React, { useState } from "react";
import "../table/table.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { baseUrl } from "../../../utils/constant_api";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CircularProgress } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CategoriesTable = ({ categories }) => {
  const [open, setOpen] = useState(false);
  const [imgOpen, setImgOpen] = useState(false);
  const [name, setName] = useState("");
  const [categorieId, setCategorieId] = useState();
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCategorieId(id);
  };

  const handleImgClickOpen = (id) => {
    setImgOpen(true);
    setCategorieId(id);
  };

  const handleImgClose = () => {
    setImgOpen(false);
  };

  const handleImageChange = (e) => {
   
    if (e.target.files[0]) {
      setImages(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("images", file);
    axios
      .put(`${baseUrl}/categories/upload/${categorieId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        console.log(response.status);
        if (response.status === 201 || response.status === 200) {
          setLoading(false);
          // setImgOpen(false);
          // window.location.reload();
        } else {
          setError(true);
          setLoading(false);
          setErrorMessage(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    axios
      .put(
        `${baseUrl}/categories/secteur/${categorieId}`,
        { name, description },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === "ok") {
          setOpen(false);
        } else {
          setError();
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  return (
    <div className="tableContain paddings flexCenter ">
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Tracking ID</TableCell>
              <TableCell className="tableCell m-5">Catégories</TableCell>
              <TableCell className="tableCell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories?.map((categorie, i) => (
              <TableRow key={i}>
                <TableCell className="tableCell">{categorie._id}</TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img
                      src={categorie.images[0]?.url}
                      alt=""
                      className="image"
                    />
                    {categorie.name}
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="">
                    <button
                      className="button"
                      onClick={() => handleImgClickOpen(categorie._id)}
                    >
                      Ajouter une image
                    </button>

                    <Dialog open={imgOpen} onClose={handleImgClose}>
                      <DialogTitle>
                        Ajoutez une image à la catégorie {categorie.name}
                      </DialogTitle>

                      {error ? (
                        <div className="errorMessage">
                          <i className="fa fa-warning text-danger fa-2x"></i>
                          <p>l'image n'a pas été Ajouter</p>
                        </div>
                      ) : (
                        <span></span>
                      )}
                      <DialogContent>
                        {images && <img src={images} alt="Preview" />}
                        <Button
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload file
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleImageChange}
                          />
                        </Button>
                      </DialogContent>
                      {isLoading ? (
                        <div className="flex items-center justify-center p-4">
                          <CircularProgress />
                        </div>
                      ) : (
                        <DialogActions>
                          <Button onClick={handleImgClose}>Annuler</Button>
                          <Button onClick={handleImageSubmit}>Ajouter</Button>
                        </DialogActions>
                      )}
                    </Dialog>

                    <button
                      className="button ml-5"
                      onClick={() => handleClickOpen(categorie._id)}
                    >
                      Ajouter un secteur
                    </button>
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>
                        Ajoutez un secteur à la catégorie {categorie.name}
                      </DialogTitle>
                      <DialogContent>
                        <TextField
                          autoFocus
                          margin="dense"
                          name="name"
                          value={name}
                          onChange={handleNameChange}
                          label="Nom du secteur"
                          type="text"
                          fullWidth
                          variant="standard"
                        />
                        <TextField
                          autoFocus
                          margin="dense"
                          name="description"
                          value={description}
                          onChange={handleDescriptionChange}
                          label="Description"
                          type="text"
                          fullWidth
                          variant="standard"
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button onClick={handleSubmit}>Ajouter</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CategoriesTable;
