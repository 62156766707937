import React from "react";
import "./CreateProject.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import axios from 'axios'

const CreateProject = () => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="project-wrapper">
      <div className="paddings innerWidth">
        <h1 className="primaryText">Creation de projet</h1>
        <div className="paddings">

        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;