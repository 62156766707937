import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import "./Login.css";

const ConfirmMail = () => {
  const email = localStorage.getItem("email");
  const [errorMessage, setError] = useState("");
  const [verif_code, setCode] = useState({ verif_code: "" });

  const handleChange = (e) => {
    setCode({ ...verif_code, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    axios
      .post(`${baseUrl}/auth/verification`, verif_code)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "ok") {
          localStorage.setItem("token", res.data.token);
          window.location.href = "/";
          //navigate("/");
        } else {
          setError(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const resetEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${baseUrl}/auth/code/resend`, { email: email })
      .then((res) => {
        setLoading(false);
        if (res.data.status === "ok") {
          setSuccess(true);
          setError(res.data.message);
        } else {
          setError(res.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="login paddinds container">
      <div className="paddings w-full max-w-xl shadow-lg border rounded-lg my-10 gap-4 mx-2">
        <div className="mx-auto rounded-full border shadow-lg w-32 h-32 items-center flex p-3">
          <img src="./images/logo.png" alt="" className="" />
        </div>

        <form action="flex justify-center w-full" className="" onSubmit={handleSubmit}>
          <div className="w-full p-4">
            <h3 className="font-semibold text-xl text-center ">vérifiez votre adresse mail</h3>
            <h3 className="text-center text-gray-400 font-medium mb-2">{email}</h3>
            {errorMessage !== "" && !success ? (
              <div className="btn btn-outline-danger w-53 my-1 flex p-2 items-center">
                <i className="fa fa-warning"></i> 
                <p>{errorMessage}</p>
              </div>
            ) : (
              <span></span>
            )}
            {errorMessage !== "" && success ? (
              <div className="btn btn-outline-success w-53 my-1 items-center flex p-2">
                <i className="fa fa-check-circle fa-3x"></i> 
                <p>{errorMessage}</p>
              </div>
            ) : (
              <span></span>
            )}
            <div className="w-full mt-3 mb-2">
              <input
                type="text"
                className="w-full p-2 border rounded-md bg-gray-100"
                name="verif_code"
                value={verif_code.verif_code}
                placeholder="Entrez le code de confirmation"
                onChange={handleChange}
              />
            </div>{" "}
            {loading ? (
              <i className="fa fa-spinner fa-spin fa-2x"></i>
            ) : (
              <button className="w-full bg-red-700 rounded-md text-white p-2">Confirmer</button>
            )}
            <div className="mt-4 w-full flex justify-center">
              <span className="text-center text-blue-600 cursor-pointer" onClick={resetEmail}>
                Envoyer a nouveau
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmMail;
