export const posts = [
{
    id: 1, 
    title: "Une installation de climatisation parfaite",
    img: "./images/Image3.jpg",
    desc: `Les professionnels RGE, Reconnus Garants de l’Environnement que nous choisissons répondront à tous les types de travaux réactifs et planifiés jusqu'aux installations mécaniques complètes et aux rénovations. Ils concevront et installerons des systèmes de climatisation et de chaffage.`,
    longDesc: "Les professionnels RGE, Reconnus Garants de l’Environnement que nous choisissons répondront à tous les types de travaux réactifs et planifiés jusqu'aux installations mécaniques complètes et aux rénovations. Ils concevront et installerons des systèmes de climatisation, de chauffage et de ventilation. Les progrès climatiques au service de la fraîcheur de votre intérieur. Les unités de climatisation intérieures sont non seulement beaucoup plus silencieuses qu'auparavant, mais elles sont aussi beaucoup moins encombrantes. De nombreux bâtiments modernes, par exemple, ont des plafonds suspendus dans lesquels les unités peuvent être encastrées et encastrées. Lorsque cela n'est pas possible, le choix va des unités de climatisations murales à faible encombrement aux unités de climatisation au sol, du plafond aux gaines."
}, 
{
    id: 2, 
    title: "Des électriciens professionnels certifiés RGE",
    img: "./images/Electricien2.jpg",
    desc: "Notre équipe de techniciens expérimentés et certifiés RGE – Reconnus Garants de l’Environnement - est spécialement formée pour fournir des services commerciaux réactifs et une maintenance planifiée afin de garantir que vos actifs restent conformes aux normes en vigueur.",
    longDesc: "Une liste non exhaustive des services que nous vous proposons :Points d'alimentation internes et externes et accessoires pour plaques d'interrupteur Téléphone et points de données Installation d'une antenne de télévision L'éclairage, y compris la modernisation des LED à haut rendement énergétique, les pendentifs, les bandes LED, l'éclairage extérieur, l'éclairage de jardin à basse tension et bien plus encore Installation ou remplacement d'un ventilateur de plafond Modernisation du standard téléphonique Mise à niveau des dispositifs de protection des circuits Test et étiquetage des appareils électriquesVos travaux d’électricité en toute confiance avec un électricien professionnel certifié RGE En plus d'acheter la bonne quantité de matériaux et les outils spécialisés nécessaires pour couper et installer le carreau, votre électricien Reconnu Garant de l’Environnement (RGE) recruté par XXL Connexion s’assurera d'une installation sans souci pour vous de a à z.Demandez un devis gratuit pour vos besoins en matière d'électricité"
}, 
{
    id: 3, 
    title: "Optimisation de la performance",
    img: "./images/Subscrip.jpg",
    desc: "Des professionnels Reconnus garant de l’Environnement feront une analyse énergétique complète de votre projet de construction pour vous permettre d’économiser argent, énergie, et de transmettre des valeurs à vos enfants ou vos employés de bureau.",
    longDesc: "Une liste non exhaustive des services que nous vous proposons :Points d'alimentation internes et externes et accessoires pour plaques d'interrupteur Téléphone et points de données Installation d'une antenne de télévision L'éclairage, y compris la modernisation des LED à haut rendement énergétique, les pendentifs, les bandes LED, l'éclairage extérieur, l'éclairage de jardin à basse tension et bien plus encore Installation ou remplacement d'un ventilateur de plafond Modernisation du standard téléphonique Mise à niveau des dispositifs de protection des circuits Test et étiquetage des appareils électriquesVos travaux d’électricité en toute confiance avec un électricien professionnel certifié RGE En plus d'acheter la bonne quantité de matériaux et les outils spécialisés nécessaires pour couper et installer le carreau, votre électricien Reconnu Garant de l’Environnement (RGE) recruté par XXL Connexion s’assurera d'une installation sans souci pour vous de a à z.Demandez un devis gratuit pour vos besoins en matière d'électricité"
}, 

]